import { Logout as LogoutIcon, Person as PersonIcon } from '@mui/icons-material'
import { AppBar, Hidden, Stack, Tooltip, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Box, useTheme } from '@mui/system'
import { signIn } from 'next-auth/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import ChatButtonContainer from '../features/Chat/containers/ChatButtonContainer'
import NotificationButtonContainer from '../features/Chat/containers/NotificationButtonContainer'
import useLocalization from '../features/localization/hooks/useLocalization'
import ReadingPlanStatusContainer from '../features/readingPlan/containers/ReadingPlanStatusContainer'
import { useGetReadingPlanForUserQuery } from '../generated/graphql'
import useAuthClaims from '../hooks/useAuthClaims'
import { handleSignOut } from '../lib/auth0'
import { HLO, UserMenuItemType } from '../types/global.d'
import HeaderDivider from './HeaderDivider'
import HeaderMenu from './HeaderMenu'
import Logo from './Logo'
import PageContainer from './PageContainer'
import SkipToMain from './SkipToMain'
import UserBadge from './UserBadge'
import UserMenu, { UserMenuItem } from './UserMenu'

const Header = () => {
  const { claims, loading, userId } = useAuthClaims()
  const { language } = useLocalization()
  const role = claims?.role || ''
  const router = useRouter()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const queries = {
    readingPlans: useGetReadingPlanForUserQuery({
      skip: !language || !userId || !['reader'].includes(role),
      variables: { languageId: language?.id, userId: userId as string },
    }),
  }

  const readingPlan =
    queries.readingPlans.data &&
    Array.isArray(queries.readingPlans.data.readingPlans) &&
    queries.readingPlans.data.readingPlans.length > 0
      ? queries.readingPlans.data.readingPlans[0]
      : undefined
  const remainingReadings = readingPlan ? readingPlan?.remainingreadings : 0
  const readingPlanExpireDate = readingPlan
    ? new Date(readingPlan.valid_to)
    : undefined

  const showMenuItems = router.pathname.indexOf('/onboarding') === -1

  const handleMenuItemClick = ({ onClick, type, value }: UserMenuItem) => {
    if (type === UserMenuItemType.LINK && typeof value === 'string') {
      router.push(value)
    }

    if (type === UserMenuItemType.BUTTON && typeof onClick === 'function') {
      onClick()
    }
  }

  const getUserMenuItems = (userRole: HLO.Role) => {
    const userMenuItems: UserMenuItem[] = []

    if (
      ['hlo-admin', 'librarian', 'book', 'reader', 'superreader'].includes(
        userRole
      )
    ) {
      userMenuItems.push({
        icon: <PersonIcon />,
        label: 'My profile',
        type: UserMenuItemType.LINK,
        value: '/profile',
      })
    }

    userMenuItems.push({
      icon: <LogoutIcon />,
      label: 'Log out',
      type: UserMenuItemType.BUTTON,
      onClick: () => handleSignOut(),
    })

    return userMenuItems
  }

  const actions = [
    claims ? (
      <Stack
        alignItems="center"
        direction="row"
        key="HeaderAction_Logout"
        spacing="24px"
      >
        {typeof remainingReadings === 'number' &&
          readingPlanExpireDate &&
          language && (
            <Hidden mdDown>
              <ReadingPlanStatusContainer />
              <HeaderDivider />
            </Hidden>
          )}
        <Stack alignItems="center" direction="row" spacing="12px">
          {claims && role !== 'guest' && <ChatButtonContainer />}
          {claims && ['hlo-admin', 'librarian'].includes(role) && (
            <NotificationButtonContainer />
          )}
          <Stack alignItems="center" direction="row" spacing="0.5rem">
            <UserMenu
              items={loading ? [] : getUserMenuItems(claims?.role)}
              onMenuItemClick={handleMenuItemClick}
            >
              <UserBadge name={claims.name || ''} />
            </UserMenu>
          </Stack>
        </Stack>
      </Stack>
    ) : (
      <Tooltip title="Log in">
        <IconButton onClick={() => signIn('auth0')}>
          <UserBadge />
        </IconButton>
      </Tooltip>
    ),
  ]

  return (
    <AppBar role="header" position="relative" component="nav">
      <PageContainer disableGutters>
        <SkipToMain />
        {isSmall ? (
          <Stack direction="column" alignItems="center" padding="24px">
            <Stack alignItems="center" direction="row" spacing={8}>
              <Logo />
              <Box sx={{}}>{actions}</Box>
            </Stack>

            {!loading &&
              ['reader', 'hlo-admin', 'librarian', 'book'].includes(
                role || ''
              ) && (
                <Box sx={{ alignItems: 'center' }}>
                  <HeaderMenu
                    selected={router.pathname}
                    showMenuItems={showMenuItems}
                    role={claims?.role}
                  />
                </Box>
              )}
          </Stack>
        ) : (
          <Stack
            color=""
            direction="row"
            alignItems="center"
            height="88px"
            padding="24px"
          >
            <Logo />
            {['hlo-admin'].includes(role) && (
              <Box
                sx={{
                  ml: '12px',
                  display: 'flex',
                  maxWidth: 52.7,
                  minWidth: 52.7,
                  minHeight: 23.5,
                }}
              >
                <Image
                  alt="The Human Library Logo"
                  height={23.5}
                  width={52.7}
                  objectFit="contain"
                  src="/Admin.svg"
                  unoptimized={process.env.NODE_ENV === 'development'}
                />
              </Box>
            )}
            {!loading &&
              ['reader', 'hlo-admin', 'librarian', 'book'].includes(
                role || ''
              ) && (
                <Box sx={{ paddingLeft: '48px' }}>
                  <HeaderMenu
                    selected={router.pathname}
                    showMenuItems={showMenuItems}
                    role={claims?.role}
                  />
                </Box>
              )}
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >
              {!loading && actions}
            </Box>
          </Stack>
        )}
      </PageContainer>
    </AppBar>
  )
}

export default Header
